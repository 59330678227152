import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import paths from '../paths'

const baseURL = '/session/archivist'

// The following CSRF constants are also constant in the backend.
const RkvstCSRFHeaderName = 'x-csrf-token' // ! lower case
const RkvstCSRFCookieName = 'jitsuin-csrf-cookie'

// TODO: consider to move this inside the Api class and only asign it
// for a class level axios object.
axios.defaults.xsrfCookieName = RkvstCSRFCookieName
axios.defaults.xsrfHeaderName = RkvstCSRFHeaderName

// Global interceptor to look for the csrf token.
// Our approach for csrf is to have the backend (webgate) set the x-csrf-token
// header on the 'whoami' request. We wanted to use the login hook but the
// headers returned from the final login flow ruedirect are not available in js.
//  The ui hits 'whoami' as the almost first request. And before any attempt at
// a post. whoami always responds with the x-csrf-token. We may later expand
// the set of requests that respond with the token (to deal with re-fresh) or
// we may just *always* send it. So the interceptor should always update the
// defaults.
// 1. https://github.com/axios/axios#interceptors
axios.interceptors.response.use(
  (response) => {
    if (response.headers[RkvstCSRFHeaderName]) {
      axios.defaults.headers.common[RkvstCSRFHeaderName] = response.headers[RkvstCSRFHeaderName]
    }
    return response
  },
  async (error: AxiosError) => {
    // if we have CSRF error just get a new cookie from whoami and retry the request
    if (error.response?.status === 403 && error.response?.data === 'Forbidden - CSRF token invalid\n') {
      const now = Date.now()
      // add a timestamp to get around any caching issues here
      await axios.get('/session/whoami?' + now)
      error.config.headers
        ? (error.config.headers[RkvstCSRFHeaderName] = axios.defaults.headers.common[RkvstCSRFHeaderName])
        : (error.config.headers = { 'x-csrf-token': axios.defaults.headers.common[RkvstCSRFHeaderName] })
      return new Promise((resolve) => resolve(axios(error.config)))
    }

    // any other error we just reject here
    return Promise.reject(error)
  }
)

interface ApiConstructor {
  baseURL?: string
  RkvstCSRFHeaderName?: string
}

/**
 * Core class for handling Api interactions
 * Please do not that the this is the class that generates the api object but not the api object itself
 * The api object is exported as default as an instatiation of this class.
 *
 * Using this class is only useful in cases that is required to reuse the same Api core code but it is required
 * to change underliny parameters without affecting other api object. For instance when we need a different
 * baseUrl
 */
export class Api {
  public RkvstCSRFHeaderName = RkvstCSRFHeaderName
  public baseURL = baseURL

  /**
   * Consturctor of the Api object
   *
   * @constructor
   * @param config {baseUrl?} [optional]
   *               baseUrl is optional and idicates the baaseUrl where all the api calls
   *               will query. It's optional value is '/session/archivist'
   *
   * @usage
   *    const api = new Api({ baseURL: '/session/auto' });
   *    api.get('/indexes/keys').then((res) => {
   *      console.log(res);
   *    });
   */
  constructor(config?: ApiConstructor) {
    if (config) {
      this.baseURL = config.baseURL !== undefined ? config.baseURL : this.baseURL
    }
  }

  /**
   * To be abl to set baseUrl using chain pattern
   * e.g. api.setBaseUrl('http://localhost:3001').post([...])
   */
  public setBaseUrl(baseURL: string) {
    this.baseURL = baseURL
    return this
  }

  /**
   * get<T = any>(url: string, data?: AxiosRequestConfig['data']): Promise<T>
   *
   * sends a GET request to the api using the internat baseUrl
   * and adding the headers required for communicating with the DataTrails
   * backend
   *
   * @param url [string] path of the request to be added in top of the baseURL
   * @param data [AxiosRequestConfig['data']] [optional]
   *
   * @return Promise<T> which will contain the result of the request
   */
  public get<T = any>(url: string, data?: AxiosRequestConfig['data'], config?: AxiosRequestConfig): Promise<T> {
    // XXX: This return type parameter isn't used.
    // See: https://dev.azure.com/jitsuin/avid/_workitems/edit/6195
    return this._write<T>('get', url, data, config)
  }

  /**
   * post(url: string, data: AxiosRequestConfig['data'], config?: AxiosRequestConfig
   *
   * sends a POST request to the api using the internat baseUrl
   * and adding the headers required for communicating with the DataTrails
   * backend
   *
   * @param url [string] path of the request to be added in top of the baseURL
   * @param data [AxiosRequestConfig['data']] [optional]
   * @param config [AxiosRequestConfig] [optional]
   *
   * @return Promise<T> which will contain the result of the request
   */
  public post<T = any>(url: string, data: AxiosRequestConfig['data'], config?: AxiosRequestConfig): Promise<T> {
    return this._write<T>('post', url, data, config)
  }

  /**
   * delete(url: string, data?: AxiosRequestConfig['data'])
   *
   * sends a DELETE request to the api using the internat baseUrl
   * and adding the headers required for communicating with the DataTrails
   * backend
   *
   * @param url [string] path of the request to be added in top of the baseURL
   * @param data [AxiosRequestConfig['data']] [optional]
   *
   * @return Promise<T> which will contain the result of the request
   */
  public delete<T = any>(url: string, data?: AxiosRequestConfig['data']): Promise<T> {
    return this._write<T>('delete', url, data)
  }

  /**
   * patch(url: string, data: AxiosRequestConfig['data'])
   *
   * sends a PATCH request to the api using the internat baseUrl
   * and adding the headers required for communicating with the DataTrails
   * backend
   *
   * @param url [string] path of the request to be added in top of the baseURL
   * @param data [AxiosRequestConfig['data']] [optional]
   *
   * @return Promise<T> which will contain the result of the request
   */
  public patch<T = any>(url: string, data: AxiosRequestConfig['data'], config?: AxiosRequestConfig): Promise<T> {
    return this._write<T>('patch', url, data, config)
  }

  /**
   * generate a resource url path so it can be uses as the url parameter of any og the RESTFUL operations
   * such as Api.get or Api.post
   *
   * @param group [string | null]
   * @param resource [string]
   * @param version [string] can only be 1, 2 or 1alpha1
   *
   * @returns [string] url path ready to be used
   */
  public parseResourceUrl(group: string | null, resource: string, version: '1' | '2' | '1alpha1') {
    const uri = `/v${version}/${resource}`
    if (group) {
      return `/${group}${uri}`
    }
    return uri
  }

  /**
   * generate a resource url path so it can be uses as the url parameter of the caps operations
   * such as Api.get
   *
   * @param resource [string]
   *
   * @returns [string] url path ready to be used for caps
   */
  public parseCapsUrl(resource: string) {
    const uri = `/v1/caps?service=${resource}`
    return uri
  }

  /**
   * remove the resources part from an id string
   *
   * @param resource [string] resource to be removed
   * @param id [string] id string containing the resource
   *
   * @returns [string] if without the resource part
   */
  public stripResourceFromId(resource: string, id: string) {
    return id?.replace(`${resource}/`, '') || id
  }

  public lastResponse: any = null

  private _write<T = any>(
    method: AxiosRequestConfig['method'],
    url: string,
    data: AxiosRequestConfig['data'],
    config?: AxiosRequestConfig,
    baseURL = this.baseURL
  ) {
    const customConfig = config || {}

    return new Promise<T>((resolve, reject) => {
      axios
        .request({
          method,
          baseURL,
          url,
          data,
          ...customConfig,
        })
        .then((response) => {
          this.lastResponse = response
          // XXX: returns data only, needs to also return a human readable status methods
          //      for example isClientError, isUnauthorised, isSuccess, isServerError
          //      and, raw status code for more granular checking
          resolve(response.data)
        })
        .catch(async (err) => {
          // if we hit 401 for any request
          // redirect to logged out page
          if (err.response && err.response.status === 401) {
            ;(window as any).location.href =
              paths.loginExpired + '?redirect=' + encodeURIComponent(window.location.pathname)
          }
          reject(err)
        })
    })
  }
}

export default new Api()
